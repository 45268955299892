
import { User } from '@/store/user/types';
import { addHours } from 'date-fns';
import { Vue, Component, Prop, VModel, Watch } from 'vue-property-decorator';

import { ROOT_ACTIONS } from '@/store/actions';
import { CalendarEvent } from '@/types';
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { firestore } from '@/firebase';

@Component
export default class UpdateEvent extends Vue {
  @VModel({ required: true }) show!: boolean;
  @Prop({ required: true }) currentEvent!: CalendarEvent;

  loaded = false;

  coaches: User[] = [];

  loading = false;

  inputs = {
    title: '',
    coach: null as null | User,
    description: '',
    updateMeeting: true
  }

  picker = {
    date: new Date().toISOString().split('T')[0],
    time: '09:00:00'
  }


  @Watch('currentEvent', { immediate: true })
  async onEvent() {
    const start = this.currentEvent.start.toDate()
    this.picker.date = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
    this.picker.time = `${start.getHours()}:${start.getMinutes()}:00`
    this.inputs.title = this.currentEvent.title
    this.inputs.description = this.currentEvent.description;

    const coach = await getDoc(doc(firestore, 'users', this.currentEvent.coach))
    if (coach.exists()) {
      this.inputs.coach = {
        ...coach.data(),
        id: coach.id,
        fullName: coach.data().firstname + ' ' + coach.data().lastname
      } as User
    } else {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: 'Error'
      })
    }

    this.loaded = true;
  }

  @Watch('inputs.coach')
  onCoachSelect() {
    if (!this.inputs.coach?.email.includes('@itinera-talentcoaching.be')) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'warning',
        text: 'Kan geen event aanmaken voor deze coach'
      })
    }
  }

  async mounted() {

    const coaches = await getDocs(query(collection(firestore, 'users'), where('type', 'in', ['COACH', 'ADMIN'])))

    this.coaches = coaches.docs.map((e) => {
      return {
        ...e.data(),
        id: e.id,
        fullName: `${e.data().firstname} ${e.data().lastname}`,
      } as User;
    });
  }

  async submit() {
    try {
      this.loading = true;
      if (this.inputs.coach) {

        const dateParts = this.picker.date.split('-');
        const timeParts = this.picker.time.split(':');

        // Create a Date object using the year, month (0-based), day, hours, and minutes
        const year = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]) - 1; // Months are 0-based (January is 0)
        const day = parseInt(dateParts[2]);
        const hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);

        const start = new Date(year, month, day, hours, minutes);
        
        const end = addHours(start, 2);
        await updateDoc(doc(firestore, 'webinars', this.currentEvent.id as string), {
          title: this.inputs.title,
          start: start,
          end: end,
          coach: this.inputs.coach.id,
          description: this.inputs.description
        })
      }

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Webinar aangapast'
      })
      this.show = false;
      this.$emit('refresh')
    } catch (error) {
      console.log(error)
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: 'Error'
      })
    } finally {
      this.loading = false;
    }
  }

}
